import { Component, ElementRef, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
//import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/_services/user.service';
import { CompaniesService } from '@app/_services/companies.service';
import { ExportToCsv } from 'export-to-csv';
import { ExportService } from '@app/_services/export.service';
import { AuthenticationService } from '../_services';
//import * as XLSX from 'xlsx';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.less']
})
export class CompaniesComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  // 
  selected_event: boolean = false
  showRange: boolean = false
  showinput: boolean = true
  getColumValue: any;
  getColumnIndex: any
  // 
  pageEvent: any;
  rolesData: any;
  postData: {};
  userForm: FormGroup;
  message = '';
  message_class = '';
  filter = '';
  loading = false;
  submitted = false;
  viewCase: boolean = true;

  public form: FormGroup;
  public companyList: FormArray;
  search_column = [];
  sort_by = [];
  order_by = [];
  search_by = [];
  search_value = [];
  columnsList = [];
  ruleList = [];
  statusList = [];
  dateList = [];
  monthList = [];
  yearList = [];
  categoryList = [];
  numMortList = [];
  filtersArray: any = [];
  selectedItems = [];
  columnsSettings = {};
  ruleSettings = {};
  statusSettings = {};
  dateSettings = {};
  monthSettings = {};
  yearSettings = {};
  categorySettings = {};
  numMortSettings = {};
  filters = {};
  showExport = false;
  is_pay_active = false;
  showCheckbox = false;
  showDefaults = false;
  showRule = false;
  showAdvanceSearch = false;
  companyStatus = false;
  charge_code = false;
  charge_status = false;
  completeDate = false;
  datesDate = false;
  monthsDate = false;
  yearsDate = false;
  accCategory = false;
  numMortsStatus = false;
  isLoading = true;
  resetDates_ = false;
  isFiltered = false;
  companies: any = [];
  fileToUpload: any = [];
  searchWithInputValue: any;
  isFileUploadCsv : boolean = false

  // returns all form groups under contacts
  get searchFormGroup() {
    return this.form.get('filters') as FormArray;
  }

  // displayedColumns: string[] = ['sr', 'CompanyName', 'CompanyNumber', 'RegAddress_CareOf', 'RegAddress_POBox', 'RegAddress_AddressLine1', 'RegAddress_AddressLine2', 'RegAddress_PostTown', 'RegAddress_County', 'RegAddress_Country', 'RegAddress_PostCode', 'CompanyCategory', 'CompanyStatus', 'CountryOfOrigin', 'DissolutionDate', 'IncorporationDate', 'Accounts_AccountRefMonth', 'Accounts_AccountCategory', 'SICCode_SicText_1', 'SICCode_SicText_2', 'SICCode_SicText_3', 'SICCode_SicText_4', 'Directors', 'Director FirstName', 'Director LastName', 'Employees Last Year', 'Employees This Year', 'Current Assets', 'Current Assets PREV', 'Fixed Assets', 'Fixed Assets PREV', 'Cash at bank', 'Cash at bank PREV', 'Accountant', 'Accountant Address', 'Turnover'];
  // displayedColumns: string[] = ['sr', 'CompanyName', 'CompanyNumber', 'RegAddress_CareOf', 'RegAddress_POBox', 'RegAddress_AddressLine1', 'RegAddress_AddressLine2', 'RegAddress_PostTown', 'RegAddress_County', 'RegAddress_Country', 'RegAddress_PostCode', 'CompanyCategory', 'CompanyStatus', 'CountryOfOrigin', 'IncorporationDate','Mortgages_NumMortCharges','Mortgages_NumMortOutstanding','Mortgages_NumMortPartSatisfied','Mortgages_NumMortSatisfied', 'SICCode_SicText_1', 'SICCode_SicText_2', 'SICCode_SicText_3', 'SICCode_SicText_4', 'Directors', 'Director FirstName', 'Director LastName', 'Employees Last Year', 'Employees This Year', 'Current Assets', 'Current Assets PREV', 'Fixed Assets', 'Fixed Assets PREV', 'Cash at bank', 'Cash at bank PREV', 'Accountant', 'Accountant Address', 'Turnover'];
  displayedColumns: string[] = ['sr', 'CompanyName', 'CompanyNumber', 'RegAddress_CareOf', 'RegAddress_POBox', 'RegAddress_AddressLine1', 'RegAddress_AddressLine2', 'RegAddress_PostTown', 'RegAddress_County', 'RegAddress_Country', 'RegAddress_PostCode', 'CompanyCategory', 'CompanyStatus', 'CountryOfOrigin', 'IncorporationDate','Accounts_AccountRefDay','Accounts_AccountRefMonth','Accounts_NextDueDate','Accounts_LastMadeUpDate','Mortgages_NumMortCharges','Mortgages_NumMortOutstanding','Mortgages_NumMortPartSatisfied','Mortgages_NumMortSatisfied', 'SICCode_SicText_1', 'SICCode_SicText_2', 'SICCode_SicText_3', 'SICCode_SicText_4', 'Directors', 'Director FirstName', 'Director LastName', 'Employees Last Year', 'Employees This Year', 'Current Assets', 'Current Assets PREV', 'Fixed Assets', 'Fixed Assets PREV', 'Cash at bank', 'Cash at bank PREV', 'Accountant', 'Accountant Address', 'Turnover'];

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  @ViewChild('byDEfaultBothValue', { static: false }) byDEfaultBothValue: ElementRef ;
  title = 'companies-export';
  // MatPaginator Inputs
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    private CompaniesService: CompaniesService,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private exportService: ExportService,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {
    this.userService.rolesData.subscribe(x => {
      //console.log("here is x",x.role_id);
      if (x.role_id == 1 || x.role_id == 2) {
        console.log("rr ", x);
        this.showExport = true;
        this.is_pay_active = x.data.is_pay_active;
      }

      this.rolesData = x.role_permission;
    });
  }

  openSnackBar(message) {
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      filters: this.fb.array([this.createContact()])
    });

    // set companyList to this field
    this.companyList = this.form.get('filters') as FormArray;

    //var year = new Date().getFullYear();
    var year = 1900;
    this.yearList = [];
    this.yearList.push(year);
    for (var i = 1; i < 200; i++) {
      this.yearList.push(year + i);
    }

    this.loadData();


  }




  showCase() {
    this.viewCase = !this.viewCase;
  }

  submitFilters() {
    this.paginator.pageIndex = 0;
    this.isFiltered = true;
    console.log(this.form.get('filters').value, 'valuegdhgf2685154121');
    this.loadData();
  }

  addSearch() {
    if (this.form.invalid) {
      this.submitted = true;
      return;
    }

    if (this.companyList.controls.length >= 1) {
      this.showCheckbox = true;
    } else {
      this.showCheckbox = false;
    }

    this.companyList.push(this.createContact());
  }

  checkFilter(data, index, type) {
    if (data[index] !== undefined) {
      return data[index][type];
    } else {
      return false;
    }

  }

  removeSearch(index) {

    this.filters[index] = { showDefaults: false, showRule: false, companyStatus: false, charge_code: false, charge_status: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, Show_rule_range: false, show_anywhere:false };
    this.companyList.removeAt(index);

    console.log(this.companyList.value)
    if (this.companyList.controls.length == 1) {
      this.showCheckbox = false;
    } else {
      this.showCheckbox = true;
    }
  }

  // get the formgroup under companies form array
  getSearchFormGroup(index): FormGroup {
    console.log("index:::" + index);
    //this.contactList = this.form.get('filters') as FormArray;
    const formGroup = this.companyList.controls[index] as FormGroup;
    return formGroup;
  }

  select_order_Column(event) {
    this.sort_by = event.target.value;
    console.log("sort_by", this.sort_by);
  }

  select_order_by(event) {
    this.order_by = event.target.value;
    console.log("order_by", this.order_by);
  }

  resetFormValues(formArray: any, index: number) {
    const initialValues = {
      search_value: null,
      complete_date: null,
      date: null,
      month: null,
      year: null,
      check: null,
      search_by: null
    };

    // Get the form group within the form array at the specified index
    const formGroup = formArray.at(index) as FormGroup;

    // Loop through the form control names and reset their values
    for (const controlName of Object.keys(initialValues)) {
      formGroup.get(controlName).setValue(initialValues[controlName], { onlySelf: true });
    }

  }

  selectColumn(event, index) {




    //  formGroup.get(controlName).setValue(initialValues[controlName]
    console.log(index, "this is iside of select colum")
    var current_val = event.target.value;
    this.getColumValue = event;
    this.getColumnIndex = index;
    if (current_val == "employee_in_2020" || current_val == "employee_in_2021") {
      // this.form.get('filters')['controls'][index]['controls']['search_by'].setValue('');
      this.selected_event = true
    }
    else {
      this.selected_event = false
      this.showRange = false
      this.showinput = true
    }
    console.log(this.selected_event)
    this.resetFormValues(this.form.get('filters') as FormArray, index);
    /*console.log(index,current_val)
    if(current_val == "CompanyNumber"){
      this.filters[index]     = {companyStatus:true}
    }else{
       this.filters[index]     = {companyStatus:false}
    }
    console.log(this.filters)*/
    // old code
    // if(current_val == "CompanyName" || current_val == "CompanyNumber" || current_val == "RegAddress_AddressLine1" || current_val == "RegAddress_AddressLine2" || current_val == "RegAddress_PostTown" || current_val == "RegAddress_County" || current_val == "RegAddress_Country" || current_val == "RegAddress_PostCode" || current_val == "SICCode_SicText_1" || current_val == "SICCode_SicText_2" || current_val == "SICCode_SicText_3" || current_val == "SICCode_SicText_4" || current_val =="employee_in_2020" || current_val=="employee_in_2021"|| current_val=="accountant")
    // if (current_val == "CompanyName" || current_val == "CompanyNumber" || current_val == "RegAddress_AddressLine1" || current_val == "RegAddress_AddressLine2" || current_val == "RegAddress_PostTown" || current_val == "RegAddress_County" || current_val == "RegAddress_Country" || current_val == "RegAddress_PostCode" || current_val == "SICCode_SicText_1" || current_val == "SICCode_SicText_2" || current_val == "SICCode_SicText_3" || current_val == "SICCode_SicText_4" || current_val == "accountant") {
    if (current_val == "CompanyName" || current_val == "CompanyNumber" || current_val == "RegAddress_AddressLine1" || current_val == "RegAddress_AddressLine2" || current_val == "RegAddress_PostTown" || current_val == "RegAddress_County" || current_val == "RegAddress_Country" || current_val == "RegAddress_PostCode" || current_val == "accountant") {
      this.filters[index] = { showRule: true, showDefaults: true, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "directors") {
      this.filters[index] = { showRule: false, showDefaults: true, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "charge_code") {
      this.filters[index] = { showRule: false, showDefaults: true, charge_code: true, charge_status: false, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "charge_status") {
      this.filters[index] = { showRule: false, showDefaults: true, charge_status: true, charge_code: false, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "CompanyStatus") {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: true, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "IncorporationDate" || current_val == "Accounts_NextDueDate" || current_val == "Accounts_LastMadeUpDate" || current_val == "Returns_NextDueDate" || current_val == "Returns_LastMadeUpDate" || current_val == "ConfStmtNextDueDate" || current_val == "ConfStmtLastMadeUpDate") {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: false, completeDate: true, datesDate: true, monthsDate: true, yearsDate: true, resetDates_: true, accCategory: false, numMortsStatus: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "Accounts_AccountRefDay") {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: false, completeDate: false, datesDate: true, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "Accounts_AccountRefMonth") {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: false, completeDate: false, datesDate: false, monthsDate: true, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "Accounts_AccountCategory") {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: true, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    } else if (current_val == "Mortgages_NumMortCharges" || current_val == "Mortgages_NumMortOutstanding" || current_val == "Mortgages_NumMortPartSatisfied" || current_val == "Mortgages_NumMortSatisfied") {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: true, resetDates_: false, Show_rule_range: false, show_anywhere:false };
    }
    // ****** new
    else if (current_val == "employee_in_2020" || current_val == "employee_in_2021") {
      this.filters[index] = { showRule: false, showDefaults: this.showinput, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_Range: this.showRange, Show_rule_range: true, show_anywhere:false }

    }
    else if (current_val == "SICCode_SicText_1" || current_val == "SICCode_SicText_2" || current_val == "SICCode_SicText_3" || current_val == "SICCode_SicText_4") {
      this.filters[index] = { showRule: false, showDefaults: true, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:true };
    }
    // ****
    else {
      this.filters[index] = { showRule: false, showDefaults: false, companyStatus: false, completeDate: false, datesDate: false, monthsDate: false, yearsDate: false, accCategory: false, numMortsStatus: false, resetDates_: false, Show_rule_range: false, show_anywhere:false };
      this.selected_event = false
    }
  }

  ngModelChange(e): void {
    console.log(e);
  }

  change(e): void {
    console.log(e);
  }


  // contact formgroup
  createContact(): FormGroup {
    return this.fb.group({
      search_column: [''],
      search_by: ['equals'],
      search_value: [null],
      complete_date: [null],
      date: [null],
      month: [null],
      year: [null],
      check: [null]
    });
  }


  navigateTo(val) {
    this.router.navigate([val]);
  }

  navigateNsave(val) {
    if (this.checkPermission(this.rolesData, 'showClaims')) {
      localStorage.setItem('companyId', val);
      this.router.navigate(['all-claims']);
    }
  }

  loadData() {
    console.log(this.form.get('filters').value, '145479879125214');
    this.isLoading = true;
    this.dataSource = new MatTableDataSource();
    this.message = '';
    const data = {
      'search_filetrs': JSON.stringify(this.form.get('filters').value),
      'filter': this.filter,
      'sort_by': this.sort_by != undefined ? this.sort_by : '',
      'order_by': this.order_by != undefined ? this.order_by : '',
      'page': this.paginator.pageIndex,
      'limit': this.paginator.pageSize != undefined ? this.paginator.pageSize : 10
    }
    this.CompaniesService.getAll(data).then((result: any) => {
      this.isLoading = false;
      if (result.status === true) {
        this.dataSource = new MatTableDataSource(result.data);
        console.log(this.dataSource, 'source');
        this.length = result.count;
        this.showAdvanceSearch = true;
      } else {
        this.dataSource = new MatTableDataSource();
        this.message = result.message;
        this.showAdvanceSearch = true;
      }
    })
      .catch((error) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource();
        this.message_class = 'danger';
        this.message = 'Network error';

      });
  }

  addEvent(index, data) {
    //~ console.log(this.form.get('filters')['controls'][index]['controls']['complete_date']['value'])
    this.form.get('filters')['controls'][index]['controls']['complete_date'].setValue(this.convert(this.form.get('filters')['controls'][index]['controls']['complete_date']['value']));

  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  exportCompanies() {
    this.isLoading = true;
    //Ecport only table elements
    /*console.log("ElementRef:",this.TABLE.nativeElement);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
    XLSX.writeFile(wb, 'Companies-data.xlsx');*/

    const data = {
      'search_filetrs': JSON.stringify(this.form.get('filters').value),
      //'gSquareTesting' : 'J-Web_expert',
      //'sortOrder'      : this.sort!=undefined?this.sort.direction:'',
      //'sortField'      : this.sort!=undefined?this.sort.active!=undefined?this.sort.active:'':'',
    }
    this.CompaniesService.ExportCompanies(data).then((result: any) => {
      if (result.status == 'rejectguest') {
        this.isLoading = false;
        this.openSnackBar(result.message);

        return false;
        // this.authenticationService.logout();
        //this.router.navigate(['/login']);
      }
      // console.log("DATA::",result.data);
      if (result.file_url != "") {
        this.downloadFile(result.file_url);
      } else {
        this.openSnackBar(result.message);
      }
      //var json = result.data;
      /*for(var i = 0; i < json.length; i++) {
        var obj = json[i];
        this.companies.push(obj);
      }*/
      //Ecport complete data
      //this.exportService.exportExcel(json, 'companies');
      this.isLoading = false;
    })
      .catch((error) => {
      });
  }

  //downloadFile(data: Response) {
  downloadFile(url) {
    //const blob = new Blob([data], { type: 'xlsx' });
    //const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

  checkPermission(jsObjects, key) {
    if (jsObjects != undefined) {
      let data = jsObjects.filter(obj => {
        return obj.permission_key === key && obj.permission_value === 1;
      })
      if (data.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  fileUpload(obj: any, type: string) {
    this.fileToUpload[type] = obj.target.files[0];
    this.isFileUploadCsv =  true;
      this.cdr.detectChanges();

    //  if (this.byDEfaultBothValue) {
      // setTimeout(() => {
      console.log(this.byDEfaultBothValue.nativeElement)
      this.getSeacrhWithValue(this.byDEfaultBothValue.nativeElement)
    // },)
  }

  submitFile(type: string) {
    if (this.fileToUpload[type] == undefined) {
      this.openSnackBar('Please choose ' + type + ' file.');
      return;
    }
    this.isLoading = true;

    var myFormData = new FormData();
    myFormData.append('type', type);
    myFormData.append('file', this.fileToUpload[type]);
    myFormData.append('searchWith', this.searchWithInputValue)

    this.CompaniesService.uploadCsvTest(myFormData)
      .then((result: any) => {
        this.isLoading = false;
        
        if (result.file_url != "") {
          this.downloadFile(result.file_url);
        } else {
          this.openSnackBar(result.message);
        };
        // this.isFileUploadCsv = false
      })
      .catch((error) => {
        this.isLoading = false;
        this.openSnackBar('Network error');

      });

  }


  resetDates(index) {
    console.log("index", index);
    console.log("filters", this.form.get('filters'));
  }

  applyFilter() {
    this.filter = (document.getElementById("filter") as HTMLInputElement).value;
    //this.isFiltered = true;
    // if(this.filter !=''){
    this.paginator.pageIndex = 0;
    this.loadData();
    // }
  }

  resetFilter() {
    this.form.get('filters').reset();
    this.isFiltered = false;
    this.paginator.pageIndex = 0;
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();
    //this.sort.sortChange.pipe(tap(() => {this.paginator.pageIndex = 0;this.loadData()})).subscribe(); 
      // console.log(this.byDEfaultBothValue.nativeElement)
      // this.cdr.detectChanges();
   

  }

  toggelRange() {

    console.log('hi')
    console.log('range')
    this.showRange = true
    this.showinput = false
    console.log(this.showRange, "inside of show toggelInput");
    console.log(this.showinput, "inside of show toggelInput");

    this.selectColumn(this.getColumValue, this.getColumnIndex)



  }
  toggelInput() {
    console.log('serach')
    this.showRange = false
    this.showinput = true
    console.log(this.getColumValue,)
    console.log(this.getColumnIndex)
    console.log(this.showRange, "inside of show toggelsearch");
    console.log(this.showinput, "inside of show toggelsearch");

    this.selectColumn(this.getColumValue, this.getColumnIndex)

  }
  findChange(event: any) {
    let val = event.target.value
    // const index = event.target.attributes.index.value; 
    const formGroup = this.getSearchFormGroup(this.getColumnIndex); // Get the form group at the specified index
    console.log('imchange')
    console.log(event.target.value, 'this is e4vent value************************')

    if (val == 'equals' || val == 'start_from' || val == 'end_to' || val == 'anywhere') {
      this.toggelInput();
      console.log('this is seacrh function')
    }
    else {
      this.toggelRange()
      console.log('this is seacrh input')

    }

    formGroup.get('search_by').setValue(val);
  }

  getSeacrhWithValue(event: any) {
    // console.log(event)
    let inputEvent = event.target as HTMLInputElement;
    if (inputEvent === undefined) {
      if(event.checked){
        this.searchWithInputValue = event.value
        console.log(this.searchWithInputValue)
      }
      return

      
    }
   else if (inputEvent) {
      if (inputEvent.checked) {
        this.searchWithInputValue = inputEvent.value
        console.log(this.searchWithInputValue )
      }
    }
  }

}
